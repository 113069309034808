import { doc,onSnapshot } from 'firebase/firestore'
import { useEffect,useState } from 'react'
import styled from 'styled-components'
import { useAuth } from '../hooks/useAuth'
import { db } from '../services/firebase'
import { Button } from './Button'
import { Card } from './Card'

export const Trial = () => {
  const auth = useAuth()
  const { uid } = auth.currentUser
  const [user,setUser] = useState({})
  const [text,setText] = useState(``)
  const [subscribed,setSubscribed] = useState(false)

  useEffect(() => {
    const getUser = async() => {
      await onSnapshot(doc(db,`users`,uid),doc => {
        if (doc.exists) setUser(doc.data())
      })
    }
    getUser()
  },[])

  useEffect(() => {
    if (!user.createdAt) return
    const createdAt = new Date(user.createdAt.toDate())
    const now = new Date()
    const diff = now - createdAt
    const days = Math.floor(diff / (1000 * 60 * 60 * 24))
    const remaining = 60 - days

    if (user.subscribed) return setSubscribed(true)

    if (remaining > 60 && remaining <= 70) setText(`You have 2 months left on your trial.`)
    else if (remaining > 30 && remaining <= 60) setText(`You have 1 month left on your trial.`)
    else if (remaining > 0 && remaining <= 30) setText(`You have ${remaining} days left on your trial.`)
    else if (remaining === 0) setText(`Your trial ends today.`)
    else if (remaining < 0) setText(`Your trial has expired.`)
  },[user])

  return !subscribed
    ? (
      <Container>
        <Card>
          <Body>
            <P>{text}</P>
            {!subscribed && <P>To continue using the app, please subscribe.</P>}
          </Body>
          <Button>Subscribe</Button>
        </Card>
      </Container>
    )
    : null
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const Body = styled.div`
  margin: 1rem 0;
  text-align: center;
  font-weight: 300;
`

const P = styled.p`
  margin: 0.5rem 0;
`
