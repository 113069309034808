import styled from 'styled-components'
import { Input } from '../components/Input'
import { useOnboarding } from '../hooks/useOnboarding'
import { Button } from '../components/Button'
import { useNavigate } from 'react-router-dom'
import { bright } from '../utils/colors'
import { validate } from '../utils/validate'

export const Step1 = () => {
  const [onboarding,setOnboarding] = useOnboarding()
  const navigate = useNavigate()
  const schema = { type: `number`,minimum: 60 }
  const value = Number(onboarding.hourlyRate) || ``
  const errors = validate(value,schema)

  const handleKeyDown = e => {
    if (e.key === `Enter`) handleClick()
  }

  const handleClick = () => navigate(`/onboarding/2`)

  return (
    <Container>
      <h1>My hourly rate is</h1>
      <Row>
        <Text>£</Text>
        <Input
          css={`
          text-align:center;
          .error{
            max-width:100px;
          }
        `}
          style={{ textAlign: `center`,fontSize: 32,maxWidth: 100 }}
          placeholder={100}
          onKeyDown={handleKeyDown}
          value={value}
          onChange={hourlyRate => setOnboarding(s => ({ ...s,hourlyRate: Number(hourlyRate) }))}
          schema={schema}/>
        <Text>/hour</Text>
      </Row>
      <h2>(You can always adjust it later...)</h2>
      <Button
        onClick={handleClick}
        disabled={errors.length || !value}
        style={{
          color: bright,
          marginTop: 20,
          width: `min(200px,80vw)`,
        }}>next →</Button>
    </Container>
  )
}

const Container = styled.div`
  display:flex;
  height:100vh;
  width:100vw;
  align-items:center;
  justify-content:center;
  flex-direction:column;
  background-color:var(--bright);
  gap:20px;
  text-align:center;
  h2{
    font-size:16px;
  }

`
const Row = styled.div`
  display:flex;
  align-items:center;
  gap:10px;
`
const Text = styled.div`
  font-size:32px;
`
