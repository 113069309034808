import styled from 'styled-components'

export const ScrollableContainer = styled.div`
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: var(--darkgray);
  }
  &::-webkit-scrollbar-thumb {
    background: var(--dark);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: var(--dark);
  }
`
