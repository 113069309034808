import {
  bright,brightgray,dark,darkest,darkgray,light,lightgray,
} from '../utils/colors'

export const useColors = () => {
  const { style } = document.querySelector(`:root`)
  style.setProperty(`--darkest`,darkest)
  style.setProperty(`--dark`,dark)
  style.setProperty(`--bright`,bright)
  style.setProperty(`--light`,light)
  style.setProperty(`--darkgray`,darkgray)
  style.setProperty(`--lightgray`,lightgray)
  style.setProperty(`--brightgray`,brightgray)
}
