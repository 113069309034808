import styled from 'styled-components'

export const LogoRed = props => {
  return (
    <Img
      {...props}
      src={`/number4-high-resolution-logo-black-on-white-background.png`}/>
  )
}

const Img = styled.img`
  max-width:min(500px,100vw);
  width:100%;
  padding:20px;
  object-fit:contain;
  box-sizing:border-box;
`

