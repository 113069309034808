import styled from 'styled-components'
import { Input } from '../components/Input'
import { useOnboarding } from '../hooks/useOnboarding'
import { Button } from '../components/Button'
import { useNavigate } from 'react-router-dom'
import { bright } from '../utils/colors'
import { validate } from '../utils/validate'
import { addDoc,collection } from 'firebase/firestore'
import { db } from '../services/firebase'

export const Step2 = () => {
  const [onboarding,setOnboarding] = useOnboarding()
  const navigate = useNavigate()
  const schema = { type: `number`,minimum: 7000000000,maximum: 8000000000 }
  const value = Number(onboarding.mobile) || ``
  const errors = validate(value,schema)

  const handleKeyDown = e => {
    if (e.key === `Enter`) handleClick()
  }

  const handleClick = async() => {
    navigate(`/onboarding/3`)
    const o = await addDoc(collection(db,`onboarding`),{ ...onboarding,mobile: `+44${onboarding.mobile}` })
    setOnboarding(s => ({ ...s,id: o.id,mobile: `+44${s.mobile}` }))
  }

  return (
    <Container>
      <h1>My mobile number is</h1>
      <Row>
        <Text>+44</Text>
        <Input
          css={`
          text-align:center;
          max-width:220px;
          .error{
            max-width:220px;
          }
          .input{
            font-size:32px;
          }
        `}
          placeholder={7587654321}
          value={value}
          onKeyDown={handleKeyDown}
          onChange={mobile => setOnboarding(s => ({ ...s,mobile }))}
          schema={schema}/>
      </Row>
      <Button
        onClick={handleClick}
        disabled={errors.length || !value}
        style={{
          color: bright,
          marginTop: 20,
          width: `min(200px,80vw)`,
        }}>next →</Button>
    </Container>
  )
}

const Container = styled.div`
  display:flex;
  height:100vh;
  width:100vw;
  align-items:center;
  justify-content:center;
  flex-direction:column;
  background-color:var(--bright);
  gap:20px;
  text-align:center;
  h2{
    font-size:16px;
  }
`
const Row = styled.div`
  display:flex;
  align-items:center;
  gap:10px;
`
const Text = styled.div`
  font-size:32px;
`
