import React from 'react'
import styled from 'styled-components'
import { Title } from '../components/Title'
import { Trial } from '../components/Trial'

export const Home = () => {
  return (
    <Container>
      <Title>Home</Title>
      <Trial/>
    </Container>
  )
}

const Container = styled.div`
  display:flex;
  flex-direction:column;
`
