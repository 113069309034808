import React,{ useEffect,useState } from 'react'
import styled from 'styled-components'
import { Title } from '../components/Title'
import { useAuth } from '../hooks/useAuth'
import { Card,CardTitle } from '../components/Card'
import { Input } from '../components/Input'
import { useUpdateDoc } from '../hooks/useUpdateDoc'
import { updateProfile } from 'firebase/auth'
import { Toggler } from '../components/Toggler'
import { doc,onSnapshot } from 'firebase/firestore'
import { db } from '../services/firebase'

export const Account = () => {
  const auth = useAuth()
  const { uid } = auth.currentUser
  const update = useUpdateDoc()
  const [user,setUser] = useState({})

  useEffect(() => {
    const getUser = async() => {
      await onSnapshot(doc(db,`users`,uid),doc => {
        if (doc.exists()) return setUser(doc.data())
      })
    }
    getUser()
  },[])

  useEffect(() => {
    const saveProfile = async() => {
      await updateProfile(auth.currentUser,{ displayName: user.name })
      await update(`users`,user.id,{ name: user.name })
    }
    saveProfile()
  },[user])

  return (
    <Container>
      <Title>Account</Title>
      <AccountCard>
        <CardTitle>Profile Details</CardTitle>
        <br/>
        { user.photoURL
          ? (
            <ProfilePic>
              <Image src={user.photoURL} alt={user.name} />
            </ProfilePic>
          )
          : null}
        <Container>
          <Row>
            <Col>
              <Label>
                Name
              </Label>
            </Col>
            <Col>
              <Input
                value={user.name}
                schema={
                  {
                    type: `string`,
                    required: true,
                    minLength: 3,
                    maxLength: 50,
                  }}
                onChange={name => setUser({ ...user,name })}/>
            </Col>
          </Row>

          <Row>
            <Col>
              <Label>
                Email
              </Label>
            </Col>
            <Col>
              <Input
                value={user.email}
                schema={
                  {
                    type: `string`,
                    required: true,
                    minLength: 5,
                    maxLength: 50,
                  }}
                onChange={email => setUser({ ...user,email })}
                disabled/>
            </Col>
          </Row>

          <Row>
            <Col>
              <Label>
                Expert Phone
              </Label>
            </Col>
            <Col>
              <Input
                value={user.ePhone}
                schema={
                  {
                    type: `string`,
                    required: true,
                    minLength: 13,
                    maxLength: 13,
                  }}
                disabled/>
            </Col>
          </Row>

          <Row>
            <Col>
              <Label>
                Personal Phone
              </Label>
            </Col>
            <Col>
              <Input
                value={user.phone}
                schema={
                  {
                    type: `string`,
                    required: true,
                    minLength: 13,
                    maxLength: 13,
                  }}
                onChange={phone => setUser({ ...user,phone })}
                disabled/>
            </Col>
          </Row>

          <Row>
            <Col>
              <Label>
                Enable First Trial Call
              </Label>
            </Col>
            <Col>
              <Toggler />
            </Col>
          </Row>

        </Container>
      </AccountCard>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`
const AccountCard = styled(Card)`
  min-width: 30rem;
`

const ProfilePic = styled.div`
  width: 10em;
  height: 10em;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
  margin-bottom: 1rem;
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;  
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
`

const Label = styled.div`
  color: #999;
`
