export const number4FirebaseConfig = {
  apiKey: `AIzaSyARD2mlaN3NuLpXLVoJWyjuhi5KjM3ZOeo`,
  authDomain: `wakeflow-number4.firebaseapp.com`,
  projectId: `wakeflow-number4`,
  storageBucket: `wakeflow-number4.appspot.com`,
  messagingSenderId: `83190133917`,
  appId: `1:83190133917:web:b7ca6e7c5467cb4c60d074`,
  measurementId: `G-QDZQK3XD4L`,
}

export const wakeflowPaymentsConfig = {
  apiKey: `AIzaSyA1_PS6FuTewNe68gPWdLml_wYO4ojxs6Y`,
  authDomain: `wakeflow-payments.firebaseapp.com`,
  projectId: `wakeflow-payments`,
  storageBucket: `wakeflow-payments.appspot.com`,
  messagingSenderId: `210219892479`,
  appId: `1:210219892479:web:ec1443415f26a3d4d696ca`,
  measurementId: `G-LC9BWK41V5`,
}
