import React from 'react'
import { Route,Routes,useLocation,useNavigate } from 'react-router-dom'
import { Home } from '../pages/Home'
import { Landing } from '../pages/Landing'
import { MainLayout } from './MainLayout'
import { Login } from './Login'
import { onAuthStateChanged } from 'firebase/auth'
import { useAuth } from '../hooks/useAuth'
import { useUpdateDoc } from '../hooks/useUpdateDoc'
import { Logout } from './Logout'
import { ProtectedRoute } from './ProtectedRoute'
import { Interactions } from '../pages/Interactions'
import { Transactions } from '../pages/Transactions'
import { Account } from '../pages/Account'
import { Step1 } from '../pages/Step1'
import { Step2 } from '../pages/Step2'
import { Step3 } from '../pages/Step3'
import { Step4 } from '../pages/Step4'

export const Router = () => {
  const auth = useAuth()
  const update = useUpdateDoc()
  const location = useLocation()
  const navigate = useNavigate()
  onAuthStateChanged(auth,user => {
    if (!user) return
    if (!auth.currentUser) return
    if (location.pathname === `/login` || location.pathname === `/`) navigate(`/home`)
    const { email,displayName,photoURL,uid,phoneNumber } = user
    const userObj = { email,name: displayName,photoURL,uid }
    if (phoneNumber) userObj.phone = phoneNumber
    update(`users`,user.uid,userObj)
  })

  return (
    <Routes>
      <Route path="/" element={<Landing/>}/>
      <Route path="/home" element={<ProtectedRoute><MainLayout><Home/></MainLayout></ProtectedRoute>}/>
      <Route path="/interactions" element={<ProtectedRoute><MainLayout><Interactions/></MainLayout></ProtectedRoute>}/>
      <Route path="/transactions" element={<ProtectedRoute><MainLayout><Transactions/></MainLayout></ProtectedRoute>}/>
      <Route path="/onboarding/1" element={<Step1/>}/>
      <Route path="/onboarding/2" element={<Step2/>}/>
      <Route path="/onboarding/3" element={<Step3/>}/>
      <Route path="/onboarding/4" element={<Step4/>}/>
      <Route path="/account" element={<ProtectedRoute><MainLayout><Account/></MainLayout></ProtectedRoute>}/>
      <Route path="/login" element={<Login/>}/>
      <Route path="/logout" element={<Logout/>}/>
    </Routes>
  )
}
