import styled from 'styled-components'
import { useState } from 'react'
import { validate } from '../utils/validate'

export const Input = ({ schema,value,onChange,css,...rest }) => {
  const [touched,setTouched] = useState(false)
  const errors = validate(value,schema)
  return (
    <Container css={css}>
      <StyledInput
        className="input"
        {...rest}
        onBlur={() => setTouched(true)}
        type={schema.type === `number` ? `number` : `text`}
        value={value}
        onChange={e => onChange(e.target.value)}/>
      {!!errors.length && touched && <Error className="error">
        {errors.map(e => e.message).join(`, `)}
      </Error>}
    </Container>
  )
}

const Container = styled.div`
  display:flex;
  flex-direction:column;
  padding:5px;
  border-radius:2px;
  background-color:white;
  ${p => p.css}
`
const StyledInput = styled.input`
  text-align:center;
  border:none;
  padding:5px;
  border-radius:4px;
  background-color:transparent;
  &:focus{
    outline:none;
  }
`
const Error = styled.div`
  color:var(--bright);
  font-family:Inter;
  font-size: 16px;
  padding:5px;
`
