import './App.css'
import 'react-toastify/dist/ReactToastify.css'
import { BrowserRouter } from 'react-router-dom'
import { Router } from './components/router'
import { useColors } from './hooks/useColors'
import { ToastContainer } from 'react-toastify'

const App = () => {
  useColors()
  return (
    <BrowserRouter>
      <Router/>
      <ToastContainer/>
    </BrowserRouter>
  )
}

export default App
