import { GoogleAuthProvider,signInWithPopup,getAuth } from 'firebase/auth'
import { app } from '../services/firebase'

export const useGoogleAuth = () => {
  const provider = new GoogleAuthProvider()
  provider.addScope(`https://www.googleapis.com/auth/contacts.readonly`)
  const auth = getAuth(app)
  return () => {
    return signInWithPopup(auth,provider)
  }
}
