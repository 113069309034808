import React from 'react'
import styled from 'styled-components'

export const Toggler = ({ onChange,checked,label }) => (
  <Container>
    <Label style={{ color: checked ? `var(--dark)` : `var(--light)` } }>{label}</Label>
    <StyledToggler onChange={onChange}>
      <Input type="checkbox" checked={checked} />
      <StyledTogglerButton checked={checked} />
    </StyledToggler>
  </Container>
)

const StyledToggler = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
`

const StyledTogglerButton = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
    ${props => props.checked && `
      transform: translateX(26px);
      background-color: var(--dark);
    `}
  }
`

const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`

const Container = styled.div`
  display: flex;
  align-items: center;
`

const Label = styled.div`
  margin-right: 1rem;
  font-weight: bold;
`
