import styled from 'styled-components'

export const LogoWhite = () => {
  return (
    <Img src={`/number4-high-resolution-logo-white-on-transparent-background.png`}/>
  )
}

const Img = styled.img`
  max-width:500px;
  padding:20px;
  box-sizing:border-box;
`

