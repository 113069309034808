import styled from 'styled-components'

export const Button = ({ children,...props }) => {
  return (
    <Container
      {...props}>
      {children}
    </Container>
  )
}

const Container = styled.button`
  display:flex;
  width:100%;
  text-align:center;
  align-items:center;
  justify-content:center;  
  font-size:24px;
  border-radius:4px;
  cursor: pointer;
  border:none;
  padding:10px;
`
