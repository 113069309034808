import React from 'react'
import styled from 'styled-components'
import { ScrollableContainer } from './ScrollableContainer'

export const Table = ({ data,columns,onClick }) => {
  return (
    <TableContainer>
      <StyledTable>
        <Thead>
          <Tr>
            {columns.map(({ accessor,title }) => (<StyledTableHeader key={accessor}> {title} </StyledTableHeader>))}
          </Tr>
        </Thead>
        <Tbody>
          {data.map((row,index) => (
            <StyledTableRow key={index} onClick={() => onClick(row)}>
              {columns.map(({ accessor,Cell,Style }) => {
                return <StyledTableCell key={accessor} style={Style ? Style({ row }) : {}}>
                  {Cell ? <Cell row={row}/> : row[accessor]}
                </StyledTableCell>
              })}
            </StyledTableRow>
          ))}
        </Tbody>
      </StyledTable>
    </TableContainer>
  )
}

const TableContainer = styled(ScrollableContainer)`
  overflow-x: auto;
  max-height: 80vh;
`

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`

const StyledTableHeader = styled.th`
  padding: 10px;
  background-color: var(--darkgray);
  color: white;
`

const StyledTableRow = styled.tr`
  &:nth-child(even) {
    background-color: var(--brightgray);
  }
  cursor: pointer;
`

const StyledTableCell = styled.td`
  padding: 10px;
  text-align: center;
  vertical-align: middle;
`

const Thead = styled.thead`
    background-color: var(--darkgray);
    color: white;
`

const Tbody = styled.tbody`
`

const Tr = styled.tr`
`

