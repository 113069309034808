import React from 'react'
import styled from 'styled-components'
import { ChatMessage } from './ChatMessage'
import { ScrollableContainer } from './ScrollableContainer'

export const ChatUI = ({ chat }) => {
  return (
    <ChatContainer>
      <MessageContainer>
        {!chat || chat.length === 0 ? <Error>No messages</Error> : null}
        {chat?.length > 0 && chat.map((message,index) => (<ChatMessage key={index} message={message}/>))}
      </MessageContainer>
      {/* <InputContainer>
        <Input placeholder="Enter your message here" />
      </InputContainer> */}
    </ChatContainer>
  )
}

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  min-width: 30vw;
  max-height: 80vh;
`

const MessageContainer = styled(ScrollableContainer)`
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
  color: white;
`

const InputContainer = styled.div`
  padding: 0 1rem 1rem 1rem;
`

const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid var(--lightgray);
`

const Error = styled.div`
  color: var(--dark);
`
