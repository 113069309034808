import styled from 'styled-components'
import { NavBar } from '../components/NavBar'
import { Sidebar } from '../components/Sidebar'

export const MainLayout = ({ children }) => {
  return (
    <Const>
      <NavBar />
      <Container>
        <Sidebar/>
        {children}
      </Container>
    </Const>
  )
}

const Const = styled.div`
  height:100vh;
  display:flex;
  flex-direction:column;
  background-color:white;
`
const Container = styled.div`
  height:100%;
  display:flex;
  background-color:white;
`
