import styled from 'styled-components'

export const Card = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: 1rem;
`

export const CardTitle = styled.h3`
  margin: 0;
  padding: 0.5rem;
  background-color: #eee;
  text-align: center;
`
