import { BsTelephoneFill } from 'react-icons/bs'
import { MdOutlineMessage } from 'react-icons/md'
import styled from 'styled-components'
import { formatDate } from '../utils/formatDate'

export const ChatMessage = ({ message }) => {
  const Icon = message.type === `call` ? BsTelephoneFill : MdOutlineMessage
  const BubbleComponent = message.isReceived ? RecievedBubble : SendBubble

  return (
    <BubbleComponent>
      <Bubble>
        <IconContainer>
          <Icon />
          {message.text}
        </IconContainer>
      </Bubble>
      <Timestamp>{formatDate(message.time)}</Timestamp>
    </BubbleComponent>
  )
}

const Bubble = styled.div`
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  max-width: 60%;

  animation: enlarge 0.5s;
  @keyframes enlarge {
    from {
      transform: scale(0.5);
    }
    to {
      transform: scale(1);
    }
  }
`

const SendBubble = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 1rem;

  ${Bubble} {
    background-color: var(--darkgray);
    align-self: flex-end;
  }
`

const RecievedBubble = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;

  ${Bubble} {
    background-color: var(--dark);
    align-self: flex-start;
  }
`

const Timestamp = styled.div`
  font-size: 0.75rem;
  align-self: center;
  margin-top: 0.25rem;
  color: var(--lightgray);
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`
