import { initializeApp } from 'firebase/app'
import { getFirestore,Timestamp } from 'firebase/firestore'
import { number4FirebaseConfig,wakeflowPaymentsConfig } from '../config'

export const app = initializeApp(number4FirebaseConfig)
export const paymentsApp = initializeApp(wakeflowPaymentsConfig,`payments`)

export const db = getFirestore(app)
export const paymentsDb = getFirestore(paymentsApp)

export const parseCollectionSnapshot = snapshot => {
  const output = []
  snapshot.forEach(item => {
    output.push(parseDocSnapshot(item))
  })
  return output
}

export const parseDocSnapshot = snapshot => {
  if (!snapshot.data) return null
  const data = snapshot.data()
  Object.keys(data).forEach(key => {
    if (data[key] instanceof Timestamp)
      data[key] = data[key].toDate()
  })
  return {
    id: snapshot.id,
    ...data,
  }
}
