import styled from 'styled-components'
import { darkest } from '../utils/colors'

export const Step4 = () => {
  return (
    <Container>
      <h1>{`Thanks for signing up!`}</h1>
      <h1 style={{ color: darkest }} >{`We'll be in touch with your new number once we've set it up.`}</h1>
    </Container>
  )
}

const Container = styled.div`
  display:flex;
  height:100vh;
  width:100vw;
  padding:10vw;
  box-sizing:border-box;
  align-items:center;
  justify-content:center;
  flex-direction:column;
  background-color:var(--bright);
  gap:40px;
  text-align:center;
  h2{
    font-size:16px;
  }

`
