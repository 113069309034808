export const formatDate = date => {
  const d = new Date(date)
  const today = new Date()
  const yesterday = new Date(today)
  yesterday.setDate(yesterday.getDate() - 1)

  let hours = d.getHours()
  const ampm = hours >= 12 ? `PM` : `AM`
  hours = hours % 12
  hours = hours || 12
  const minutes = d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes()

  if (d.toDateString() === today.toDateString())
    return `Today ${hours}:${minutes} ${ampm}`
  else if (d.toDateString() === yesterday.toDateString())
    return `Yesterday ${hours}:${minutes} ${ampm}`
  else
    return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()} ${hours}:${minutes} ${ampm}`
}
