import React from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { AiOutlineHome,AiOutlineUser,AiOutlineDollarCircle } from 'react-icons/ai'
import { IoMdChatbubbles } from 'react-icons/io'
import { FiLogOut } from 'react-icons/fi'

const content = [
  {
    title: ``,
    items: [
      { icon: <AiOutlineHome />,text: `Home`,route: `/` },
      { icon: <AiOutlineUser />,text: `My Account`,route: `/account` },
      { icon: <IoMdChatbubbles />,text: `Interactions`,route: `/interactions` },
      { icon: <AiOutlineDollarCircle />,text: `Transactions`,route: `/transactions` },
      { icon: <FiLogOut/>,text: `Logout`,route: `/logout` },
      // { icon: <TrendingUp />,text: `Analytics`,route: `/analytics` },
    ],
  },
]

export const Sidebar = () => {
  const navigate = useNavigate()
  return (
    <SidebarContainer>
      <SidebarWrapper>
        {content.map((item,index) => (
          <SidebarMenu key={index}>
            <SidebarTitle>{item.title}</SidebarTitle>
            <SidebarList>
              {item.items.map((item,index) => (
                <SidebarItem key={index} onClick={() => navigate(item.route)}>
                  {item.icon}
                  {item.text}
                </SidebarItem>
              ))}
            </SidebarList>
          </SidebarMenu>
        ))}
      </SidebarWrapper>
    </SidebarContainer>
  )
}

const SidebarContainer = styled.div`
  display: flex;
  padding-left: 1rem;
  height: 100%;
  position: sticky;
  top: 0;
  left: 0;
  width: 200px;
  border-right: 1px solid #e5e5e5;
  background: var(--darkest);
`

const SidebarWrapper = styled.div`
  padding: 20px;
  color: white;
`

const SidebarMenu = styled.div`
  margin-bottom: 50px;
`

const SidebarTitle = styled.h3`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
`

const SidebarList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`

const SidebarItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 2em;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  svg {
    margin-right: 10px;
  }
  &:hover {
    color: var(--light);
  }
`
