import { doc,setDoc } from 'firebase/firestore'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { Button } from '../components/Button'
import { Input } from '../components/Input'
import { useOnboarding } from '../hooks/useOnboarding'
import { db } from '../services/firebase'
import { bright } from '../utils/colors'
import { validate } from '../utils/validate'

export const Step3 = () => {
  const [onboarding,setOnboarding] = useOnboarding()
  const navigate = useNavigate()
  const schema = { type: `string`,format: `email`,maxLength: 100,minLength: 5,pattern: `^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$` }
  const errors = validate(onboarding.email,schema)
  const value = onboarding.email || ``


  const handleKeyDown = e => {
    if (e.key === `Enter`) handleClick()
  }

  const handleClick = async() => {
    navigate(`/onboarding/4`)
    const { id,...onboardingObj } = onboarding
    await setDoc(doc(db,`onboarding`,id),{ ...onboardingObj,email: onboardingObj.email.toLowerCase() },{ merge: true })
  }

  return (
    <Container>
      <h1>My email address is</h1>
      <Row>
        <Input
          css={`
          text-align:center;
          max-width:500px;
          .error{
            max-width:500px;
          }
          .input{
            font-size:32px;
          }
        `}
          placeholder={`user@domain.com`}
          value={value}
          onKeyDown={handleKeyDown}
          onChange={email => setOnboarding(s => ({ ...s,email }))}
          schema={schema}/>
      </Row>
      <Button
        onClick={handleClick}
        disabled={errors.length || !value}
        style={{
          color: bright,
          marginTop: 20,
          width: `min(200px,80vw)`,
        }}>next →</Button>
    </Container>
  )
}

const Container = styled.div`
  display:flex;
  height:100vh;
  width:100vw;
  align-items:center;
  justify-content:center;
  flex-direction:column;
  background-color:var(--bright);
  gap:20px;
  text-align:center;
  h2{
    font-size:16px;
  }
`
const Row = styled.div`
  display:flex;
  align-items:center;
  gap:10px;
`
