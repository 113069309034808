import styled from 'styled-components'
import { Button } from './Button'
import { useGoogleAuth } from '../hooks/useGoogleAuth'
import { LogoWhite } from './LogoWhite'
import { Input } from './Input'
import { useState } from 'react'
import googleLogo from '../assets/googleLogo.svg'

export const Login = () => {
  const [email,setEmail] = useState(``)
  const signInWithGoogle = useGoogleAuth()
  const handleClick = async() => {
    signInWithGoogle()
      .then(console.log)
      .catch(console.log)
  }

  return (
    <Container>
      <LogoWhite/>
      <Card>
        <Button onClick={handleClick}>
          <div>Sign in with</div>
          <GoogleLogo src={googleLogo}/>
          <div>Google</div>

        </Button>
        <p>or</p>
        <Input
          value={email}
          onChange={setEmail}
          placeholder="you@example.com"
          schema={{
            required: true,
            type: `string`,
            format: `email`,
          }}/>
        <Button onClick={handleClick}>
          <div>Sign in with Email</div>
        </Button>
      </Card>
    </Container>
  )
}

const Container = styled.div`
  display:flex;
  align-items:center;
  justify-content:center;
  background-color:black;
  height:100vh;
  p{
    font-size:24px;
    width:100%;
    text-align:center;
  }
`
const Card = styled.div`
  background-color:var(--bright);
  height:400px;
  width:300px;
  border-radius:20px;
  padding:30px;
  display:flex;
  flex-direction:column;
  gap:20px;
`
const GoogleLogo = styled.img`
  margin:10px;
`
