import { query,where,collection,getDocs,onSnapshot,doc } from 'firebase/firestore'
import React,{ useEffect,useState } from 'react'
import styled from 'styled-components'
import { useAuth } from '../hooks/useAuth'
import { parseCollectionSnapshot,db } from '../services/firebase'
import { Card,CardTitle } from '../components/Card'
import { formatDate } from '../utils/formatDate'
import { formatDuration } from '../utils/formatDuration'
import { ChatUI } from '../components/ChatUI'
import { Table } from '../components/Table'
import { BsTelephoneFill } from 'react-icons/bs'
import { MdOutlineMessage } from 'react-icons/md'
import { bright,dark,darkest } from '../utils/colors'

export const Interactions = () => {
  const auth = useAuth()
  const { uid } = auth.currentUser
  const [selectedInteraction,setSelectedInteraction] = useState(null)
  const [chat,setChat] = useState([])

  const [user,setUser] = useState({})
  useEffect(() => {
    const getUser = async() => {
      await onSnapshot(doc(db,`users`,uid),doc => {
        if (doc.exists()) return setUser(doc.data())
      })
    }
    getUser()
  },[])

  const [interactions,setInteractions] = useState([])


  const getInteractions = async(destination,contact) => {
    if (!destination || !contact) return null
    const q = query(collection(db,`interactions`),where(destination,`==`,contact))
    return parseCollectionSnapshot(await getDocs(q))
  }

  const getAllInteractions = async() => {
    let interactions = []
    const sendInteractions = await getInteractions(`from`,user.phone)
    if (sendInteractions) interactions.push(...sendInteractions)
    const receivedInteractions = await getInteractions(`to`,user.phone)
    if (receivedInteractions) interactions.push(...receivedInteractions)
    interactions = interactions.sort((a,b) => b.createdAt - a.createdAt)
    setInteractions(interactions)
    setSelectedInteraction(interactions[0])
  }

  const getConversations = async({ from,to }) => {
    if (!from || !to) return null
    const q = query(collection(db,`interactions`),where(`from`,`==`,from),where(`to`,`==`,to))
    return parseCollectionSnapshot(await getDocs(q))
  }

  useEffect(() => { getAllInteractions() },[user])

  const formatConversation = interaction => {
    if (interaction.type === `call`) return {
      type: interaction.type,
      time: interaction.createdAt,
      text: `${interaction.duration ? `Duration: ${formatDuration(interaction.duration)}` : `Missed Call`}`,
      isReceived: interaction.from === user.phone,
    }
    if (interaction.type === `sms`)
      return {
        type: interaction.type,
        text: interaction.body,
        time: interaction.createdAt,
        isReceived: interaction.from === user.phone,
      }
  }

  useEffect(() => {
    if (!selectedInteraction) return
    console.log(`selectedInteraction`,selectedInteraction)
    let interactions = []
    const getChat = async() => {
      let intSend = await getConversations({ from: selectedInteraction.from,to: selectedInteraction.to })
      if (intSend) {
        intSend = intSend.map(int => formatConversation(int))
        interactions.push(...intSend)
      }

      let intReceived = await getConversations({ from: selectedInteraction.to,to: selectedInteraction.from })
      if (intReceived) {
        intReceived = intReceived.map(int => formatConversation(int))
        interactions.push(...intReceived)
      }
      if (!interactions.length) return setChat([])
      interactions = interactions.sort((a,b) => a.time - b.time)
      setChat(interactions)
    }
    getChat()
  },[selectedInteraction])

  return (
    <>
      <Container>
        <TableContainer>
          <CardTitle>Interactions</CardTitle>
          <Table
            onClick={row => setSelectedInteraction(row)}
            data={interactions}
            columns={[
              {
                title: `Type`,
                accessor: `type `,
                Cell: ({ row }) => <>{
                  row.type === `sms`
                    ? <MdOutlineMessage />
                    : <BsTelephoneFill />
                }</>,
                Style: ({ row }) => row.type === `sms` ? { color: dark } : { color: darkest },
              },
              {
                title: `From`,
                accessor: `from`,
                Cell: ({ row }) => <>{row.from === user.phone ? `Me` : row.from}</>,
              },
              {
                title: `To`,
                accessor: `to`,
                Cell: ({ row }) => <>{row.to === user.phone ? `Me` : row.to}</>,
              },
              {
                title: `Duration`,
                accessor: `duration`,
                Cell: ({ row }) => <>{row.duration ? formatDuration(row.duration) : ``}</>,
              },
              {
                title: `Message Body`,
                accessor: `body`,
              },
              {
                title: `Created At`,
                accessor: `createdAt`,
                Cell: ({ row }) => <>{row.createdAt ? formatDate(row.createdAt) : ``}</>,
              },
              {
                title: `Forwarded At`,
                accessor: `forwardedAt`,
                Cell: ({ row }) => <>{row.forwardedAt ? formatDate(row.forwardedAt) : ``}</>,
              },
            ]}/>
        </TableContainer>
        <ChatContainer>
          {selectedInteraction && <CardTitle>{selectedInteraction.from} - {selectedInteraction.to}</CardTitle>}
          {chat.length ? <ChatUI chat={chat} /> : <p>No chat</p>}
        </ChatContainer>
      </Container>
    </>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const TableContainer = styled(Card)`
  flex-basis: 60%;
  margin-right: 1rem;
  min-width: 600px;
  height: fit-content;
`

const ChatContainer = styled(Card)`
  flex-basis: 40%;
  min-width: 400px;
  height: fit-content;
`
