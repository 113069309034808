import styled from 'styled-components'
import { LogoRed } from '../components/LogoRed'
import { Button } from '../components/Button'
import { bright } from '../utils/colors'
import { useNavigate } from 'react-router-dom'

export const Landing = () => {
  const navigate = useNavigate()
  return (
    <Container>
      <Left>
        <LogoRed/>
      </Left>
      <Right>
        <Background>
          <h1>How it works</h1>
          <ol>
            <li>We give you a <Red>new mobile number</Red> that forwards to your <Red>current mobile number</Red></li>
            <li>The <Red>first call</Red> from anyone on that number is <Red>free</Red> - {`after that they pay your `}<Red>hourly rate</Red> (say £100/h) to speak to you</li>
            <li><Red>You</Red> distribute your number and <Red>get paid</Red></li>
          </ol>
          <Button
            onClick={() => navigate(`/onboarding/1`)}
            style={{
              color: bright,
              marginTop: 20,
              width: `min(200px,80vw)`,
            }}>{`Sounds good`}</Button>
        </Background>
      </Right>
    </Container>
  )
}

const Container = styled.div`
  display:flex;
  flex-wrap:wrap;
  width:100vw;
  background-color:white;
`
const Background = styled.div`
  background-color:black;
  border-radius:20px;
  padding:min(18vw,100px);
  @media only screen and (max-width: 600px) {
    border-bottom-right-radius:0;
    border-bottom-left-radius:0;
  }
`
const Red = styled.span`
  color: var(--bright);
  font-family:Inter;
  /* font-weight:100; */
`
const Left = styled.div`
  background-color: white;
  min-height:100vh;
  flex:1 1 100px;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  box-sizing:border-box;
  @media only screen and (max-width: 600px) {
      min-height:25vh;
      max-height:25vh;
  }
`
const Right = styled.div`
  min-height:100vh;
  flex:1 1 100px;
  max-width:100vw;
  background-color:white;
  color:white;
  gap:20px;
  display:flex;
  flex-direction:column;
  justify-content:center;
  box-sizing:border-box;
  h1{
    margin-bottom:0;
  }
  li{
    font-family:Inter;
    font-size:24px;
    padding-top:15px;
  }
  margin-right:10vw;
  @media only screen and (max-width: 600px) {
    margin-right:0;
  }
`

