import { collection,getDocs,query,where } from 'firebase/firestore'
import React,{ useEffect,useState } from 'react'
import styled from 'styled-components'
import { Card,CardTitle } from '../components/Card'
import { useAuth } from '../hooks/useAuth'
import { parseCollectionSnapshot,paymentsDb } from '../services/firebase'
import { formatDate } from '../utils/formatDate'
import { Table } from '../components/Table'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import { RxCross2 } from 'react-icons/rx'

export const Transactions = () => {
  const auth = useAuth()
  const { email } = auth.currentUser
  const [transactions,setTransactions] = useState([])

  useEffect(() => {
    const getTransactions = async() => {
      const q = query(collection(paymentsDb,`transactions`),where(`email`,`==`,email))
      const data = parseCollectionSnapshot(await getDocs(q))
      if (!data.length) return
      setTransactions(data)
    }
    getTransactions()
    return () => {
      console.log(`Transactions unmount`)
    }
  },[])

  return (
    <Container>
      <Card>
        <CardTitle>Transactions</CardTitle>
        <Table
          data={transactions}
          columns={[
            {
              title: `Date`,
              accessor: `date`,
              Cell: ({ row }) => <>{row.createdAt ? formatDate(row.createdAt) : ``}</>,
            },
            {
              title: `Amount`,
              accessor: `amount`,
              Cell: ({ row }) => <>{row.amount} {row.currency.toUpperCase()}</>,
            },
            {
              title: `Payment Status`,
              accessor: `status`,
              Cell: ({ row }) => row.status === `succeeded` ? <BsFillCheckCircleFill /> : <RxCross2 />,
              Style: ({ row }) => row.status === `succeeded` ? { color: `green` } : { color: `red` },
            },
          ]}/>
      </Card>
    </Container>
  )
}

const Container = styled.div`
  display:flex;
  flex-direction:column;
`
