import { doc,setDoc } from 'firebase/firestore'
import { db } from '../services/firebase'

export const useUpdateDoc = () => {
  return async(collection,id,updates) => {
    if (!id || !updates || !collection) return
    const ref = doc(db,collection,id)
    await setDoc(ref,updates,{ merge: true })
  }
}
