export const formatDuration = duration => {
  let seconds = duration
  let result = ``

  const minute = 60
  const hour = 60 * minute

  if (seconds >= hour) {
    const numOfHours = Math.floor(seconds / hour)
    result += `${numOfHours} hour${numOfHours > 1 ? `s` : ``} `
    seconds = seconds % hour
  }
  if (seconds >= minute) {
    const numOfMinutes = Math.floor(seconds / minute)
    result += `${numOfMinutes} minute${numOfMinutes > 1 ? `s` : ``} `
    seconds = seconds % minute
  }
  if (seconds > 0)
    result += `${seconds} second${seconds > 1 ? `s` : ``}`

  return result.trim()
}
