import { getAuth } from 'firebase/auth'
import React from 'react'
import styled from 'styled-components'
import { useUserType } from '../hooks/useUserType'
import { LogoRed } from './LogoRed'
import { Toggler } from './Toggler'

export const NavBar = () => {
  const auth = getAuth()
  const user = auth.currentUser
  const [userType,setUserType] = useUserType()

  return (
    <NavBarContainer>
      <NavBarWrapper>
        <TopLeft>
          <Logo src={`/number4-low-resolution-logo-color-on-transparent-background.png`} />
        </TopLeft>
        <TopRight>
          <Toggler label={userType} checked={userType === `Expert`} onChange={() => setUserType(userType === `Expert` ? `Customer` : `Expert`)}/>
          {user && user.photoURL
            ? (
              <Avatar src={user.photoURL}></Avatar>
            )
            : null}

        </TopRight>
      </NavBarWrapper>
    </NavBarContainer>
  )
}

const NavBarContainer = styled.div`
    width: 100vw;
    height: 50px;
    background-color: #fff;
    position: sticky;
    top: 0;
    z-index: 999;
    padding: .5em;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`

const NavBarWrapper = styled.div`
    height: 100%;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const TopLeft = styled.div``
const TopRight = styled.div`
    display: flex;
    align-items: center;
`

const Logo = styled.img`
    width: 8em;
`

const IconContainer = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #e4e4e4;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    position: relative;
    cursor: pointer;
`

const IconBadge = styled.span`
    width: 15px;
    height: 15px;
    background-color: #ff0000;
    border-radius: 50%;
    position: absolute;
    top: -5px;
    right: -5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #fff;
    font-weight: bold;
`

const Avatar = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: 10px;
    cursor: pointer;
`
